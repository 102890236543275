import { PlaceOrderButton, setOrderType, } from 'checkout';
import { ButtonRow, Card, PageWithCards } from 'common';
import { CartContent, CartTotal, FreeFreightProgress } from 'cart';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CheckoutValidationContext } from '../checkoutValidationContext';
import CheckoutTerms from '../components/CheckoutTerms/CheckoutTerms';
import DeliveryDateCard from '../components/DeliveryDateCard/DeliveryDateCard';
import Errors from '../components/Errors/Errors';
import GiftMessageCard from '../components/GiftMessageCard/GiftMessageCard';
import './NewCheckoutPage.scss';
import RecipientCardContentGroup from 'checkout/components/RecipientCard/RecipientCardContentGroup';
import { PaymentMethodType } from 'types/paymentMethodType';
function NewCheckoutPageB2B() {
    const dispatch = useDispatch();
    const showRelatedProductsInterrupt = useSelector((state) => state.newCheckout.showRelatedProductsInterrupt);
    const shouldScrollRef = useRef(false);
    const currentPaymentMethodType = useSelector((state) => state.checkout.paymentMethodType);
    const currentOrderType = useSelector((state) => state.newCheckout.orderType);
    useEffect(() => {
        if (currentPaymentMethodType !== PaymentMethodType.B2B_INVOICE) {
            dispatch({
                type: 'SET_PAYMENT_METHOD_TYPE',
                payload: PaymentMethodType.B2B_INVOICE,
            });
        }
    }, [currentPaymentMethodType, dispatch]);
    useEffect(() => {
        if (currentOrderType !== 'group') {
            dispatch(setOrderType('group'));
        }
    }, [currentOrderType, dispatch]);
    return (React.createElement(CheckoutValidationContext.Provider, { value: shouldScrollRef },
        React.createElement(PageWithCards, { header: React.createElement(Errors, null) },
            React.createElement(Card, { title: "Din ordre", accordion: !showRelatedProductsInterrupt },
                React.createElement("div", { className: 'checkout-page__order-summary' },
                    React.createElement(CartContent, null),
                    React.createElement(CartTotal, null),
                    React.createElement(FreeFreightProgress, null))),
            React.createElement(RecipientCardContentGroup, { maxSuggestedAddresses: null }),
            React.createElement(GiftMessageCard, null),
            React.createElement(DeliveryDateCard, null),
            React.createElement(ButtonRow, { display: "center" },
                React.createElement(PlaceOrderButton, null)),
            React.createElement(CheckoutTerms, null))));
}
export default NewCheckoutPageB2B;
