import { incrementOrderAttempts, placeOrder, useCheckoutValidity, useOnInvalidOrderAttempt, useSelectedPaymentOption, } from 'checkout';
import { Button } from 'common';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cardFormatMasked, cardFormatShort } from 'utilities/text';
import './PlaceOrderButton.scss';
function PlaceOrderButton() {
    const dispatch = useDispatch();
    const [showInvalidReasons, setShowInvalidReasons] = useState(false);
    const { orderType, recipients } = useSelector((state) => state.newCheckout);
    const pricePerRecipient = useSelector((state) => state.cart.total);
    const preferredCardNo = useSelector((state) => state.paymentMethod.preferred.cardNo);
    const newCardNo = useSelector((state) => state.newPaymentMethod.cardNo);
    const selectedPaymentOption = useSelectedPaymentOption();
    const { isValid: isCheckoutValid, invalidReasons } = useCheckoutValidity();
    const paymentMethodText = useMemo(() => {
        switch (selectedPaymentOption.type) {
            case 'VIPPS':
                return ' med Vipps';
            case 'NEW_CREDIT_CARD':
                return ` med ${cardFormatShort(cardFormatMasked(newCardNo))}`;
            case 'CREDIT_CARD':
                return ` med ${cardFormatShort(cardFormatMasked(preferredCardNo))}`;
            case 'B2B_INVOICE':
                return 'invoice';
        }
        return '';
    }, [selectedPaymentOption.type, preferredCardNo, newCardNo]);
    const numberOfRecipients = Math.max(orderType === 'group' ? recipients.length : 1, 1);
    const totalPrice = pricePerRecipient * numberOfRecipients;
    const formattedTotalPrice = Number.isInteger(totalPrice) ? totalPrice : totalPrice.toFixed(2);
    const showMeansOfPayment = totalPrice > 0;
    const handleButtonClick = () => {
        if (isCheckoutValid) {
            dispatch(placeOrder());
        }
        else {
            setShowInvalidReasons(true);
            dispatch(incrementOrderAttempts());
        }
    };
    // If no components before this has claimed responsibility for the invalid checkout,
    // show the reason here as a fallback.
    useOnInvalidOrderAttempt(!isCheckoutValid, () => {
        setShowInvalidReasons(true);
    });
    const ButtonText = () => showMeansOfPayment ? (React.createElement(React.Fragment, null,
        React.createElement("span", null,
            "Betal ",
            formattedTotalPrice,
            " kr"),
        React.createElement("span", { className: "place-order-button__payment-method" }, paymentMethodText))) : (React.createElement("span", null, "Bestill"));
    const invalidReasonText = (reason) => {
        switch (reason) {
            case 'invalidContactInfo':
                return 'Manglende eller ugyldig kontaktinformasjon';
            case 'invalidCvc':
                return 'Ugyldig CVC';
            case 'invalidNumberOfRecipients':
                return 'Ugyldig antall mottakere';
            case 'invalidProductsForRegion':
                return 'Et eller flere produkter i handlekurven er ikke tilgjengelig i valgt region';
            case 'noActiveAddress':
                return 'Manglende eller ugyldig adresse';
            case 'noGiftMessage':
                return 'Mangler gavemelding';
            case 'noItemsInCart':
                return 'Ingen produkter i handlekurven';
            case 'noValidPayment':
                return 'Manglende eller ugyldig betaling';
            default:
                return 'Det er feil i skjemaet';
        }
    };
    return (React.createElement(React.Fragment, null,
        showInvalidReasons &&
            invalidReasons.map((reason) => (React.createElement("div", { className: "place-order-button__error", key: reason }, invalidReasonText(reason)))),
        React.createElement(Button, { type: "action", style: "primary", onClick: handleButtonClick },
            React.createElement(ButtonText, null))));
}
export default PlaceOrderButton;
