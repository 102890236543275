const baseState = {
    firstName: null,
    lastName: null,
    email: null,
    mobile: null,
    deliverySms: true,
    companyId: null,
    b2bCustomer: false,
}

export default function reducer(state = baseState, action) {
    switch (action.type) {
        case 'USER_LOGIN':
            return {
                ...state,
                firstName: action.payload.givenName,
                lastName: action.payload.familyName,
            }
        case 'VALIDATE_JWT_SUCCESS':
            return {
                ...state,
                mobile: action.payload.jwtData.spidMobile,
                email: action.payload.jwtData.spidEmail,
            }
        case 'USER_LOGOUT':
            return { ...baseState }
        case 'SET_PERSONAL_INFO':
            return {
                ...state,
                mobile: action.payload.mobile || state.mobile,
                firstName: action.payload.firstName || state.firstName,
                lastName: action.payload.lastName || state.lastName,
            }
        case 'SET_FIRSTNAME':
            return {
                ...state,
                firstName: action.payload,
            }
        case 'SET_LASTNAME':
            return {
                ...state,
                lastName: action.payload,
            }
        case 'SET_EMAIL':
            return {
                ...state,
                email: action.payload,
            }
        case 'SET_MOBILE':
            return {
                ...state,
                mobile: action.payload,
            }
        case 'SET_SMS_OPT-IN':
            return {
                ...state,
                deliverySms: action.payload,
            }
        case 'GET_CORE_CUSTOMER_SUCCESS':
            return {
                ...state,
                companyId: action.payload.companyId,
                b2bCustomer: !!action.payload.companyId,
            }
        default:
            return state
    }
}
