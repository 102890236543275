const baseState = {
    currentCompany: null
};
export default function reducer(state = baseState, action) {
    switch (action.type) {
        case 'GET_B2B_COMPANY_SUCCESS': {
            console.log("ff");
            const typedPayload = action.payload;
            return Object.assign(Object.assign({}, state), { currentCompany: typedPayload });
        }
        case 'USER_LOGOUT': {
            return baseState;
        }
        default:
            return state;
    }
}
