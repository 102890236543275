import isEmpty from 'lodash/isEmpty';
import { getOrderContactInfo } from 'checkout';
import { isValidAndNotEmpty } from 'utilities/validators';
export function toggleOneClickCheckout() {
    return function (dispatch, getState) {
        const state = getState();
        const personalInfo = getOrderContactInfo(state.address, state.personalInfo, state.newCheckout, state.addressHelper, state.b2bCompany);
        const userLoggedIn = state.user.isLoggedIn;
        const hasActiveAddress = state.address.active !== null;
        const hasPreferredPaymentMethod = !isEmpty(state.paymentMethod.preferred);
        const hasFilledOutContactInfo = !isEmpty(personalInfo.firstName) && !isEmpty(personalInfo.lastName) && isValidAndNotEmpty(personalInfo.mobile);
        const oneClickCheckoutEnabled = state.checkout.oneClickCheckoutEnabled;
        if (userLoggedIn &&
            hasActiveAddress &&
            hasPreferredPaymentMethod &&
            hasFilledOutContactInfo &&
            !oneClickCheckoutEnabled) {
            dispatch({ type: 'TOGGLE_ONE_CLICK_CHECKOUT', payload: true });
        }
        if ((!userLoggedIn ||
            !hasActiveAddress ||
            !hasPreferredPaymentMethod ||
            !hasFilledOutContactInfo) &&
            oneClickCheckoutEnabled) {
            dispatch({ type: 'TOGGLE_ONE_CLICK_CHECKOUT', payload: false });
        }
    };
}
