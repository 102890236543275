function mapError(error) {
    if (error.data) {
        // Old style error
        return {
            type: error.type,
            message: error.data.errorMessage,
            code: error.data.errorCode,
        }
    } else {
        return {
            type: error.code,
            message: error.type,
            code: error.message,
        }
    }
}

export function getErrorMessage(e) {
    let error = mapError(e)
    if (error.type === 'chargeError') {
        return chargeError(error)
    } else {
        return generalError(error.type)
    }
}

function chargeError({ code }) {
    // See https://pages.github.schibsted.io/spt/cc-docs/payment/guides/error-handling/#list-of-error-codes
    // See morgenlevering/source/js/controllers/checkoutController.js
    switch (code) {
        case '1702': // User is not connected with client. User didn't agree on clients agreement.
            return 'Du ser ikke ut til å ha akseptert de nye brukervilkårene for Morgenlevering. Vennligst gå til forsiden, og logg ut og inn før du forsøker igjen.'
        case '2201': // Encrypted card details from Adyen is not valid any more
            return 'På grunn av et tidsavbrudd kan ikke angitte kortdetaljer brukes lenger. Vennligst last siden på nytt og prøv igjen.'
        case '2209': // The card used for the transaction has expired
            return 'Betalingskortet er utløpt. Vennligst prøv igjen med et annet betalingskort.'
        case '5301': // Invalid or missing card number
            return 'Kortnummeret til angitt kort ser ikke ut til å være gyldig.'
        case '5302': // Invalid or missing cvc on card
        case '5305': // Invalid or missing cvc on card
            return 'Sikkerhetskoden (CVC) til angitt kort ser ikke ut til å være gyldig.'
        case '5303': // Invalid or missing card holder name
            return 'Kortinnehaverens navn til angitt kort ser ikke ut til å være gyldig.'
        case '5304': // Invalid expiry date on card
            return 'Utløpsdatoen til angitt kort ser ikke ut til å være gyldig.'
        case '1201': // Credit card not verified. Error code returned when trying to initialize order/payment involving Payex and when chosen Payex payment method (credit card) is not verified.
        case '1202': // Credit card not found. Error code returned when trying to initialize order/payment involving Payex and when chosen Payex payment method (credit card) is not found.
        case '1203': // Customer’s bank declined the transaction. Error code returned when PayEx responded with code \“PaymentRefusedByFinancialInstitution\” Payment failed because it was rejected financial institution.
        case '1204': // Credit card operation canceled by customer. Error code returned when PayEx responded with code \“OperationCancelledbyCustomer\” Customer canceled operation.
        case '1205': // Customer’s card is not eligible for this kind of purchase. Error code returned when PayEx responded with code \“CardNotEligible\” See http://www.payexpim.com/technical-reference/return-codes/
        case '1206': // Customer’s bank declined the transaction. Error code returned when PayEx responded with code \“REJECTED_BY_ACQUIRER\” See http://www.payexpim.com/technical-reference/return-codes/
        case '1207': // Some problem occurred with the credit card. Error code returned when PayEx responded with code \“CreditCard_Error\” See http://www.payexpim.com/technical-reference/return-codes/
        case '1208': // Bank rejected due to suspected duplicate request. Error code returned when PayEx responded with code \“Access_DuplicateRequest\” See http://www.payexpim.com/technical-reference/return-codes/
        case '1213': // Credit card is not accepted for this purchase. Credit card was rejected by payment service provider.
        case '1231': // Balance exceeds limits
        case '1232': // Insufficient funds
        case '1233': // No money on account
        case '1234': // Balance exceded
        case '1235': // Fraud pattern deteced
        case '1500': // Payment method not found
        case '1501': // Unauthorized access to payment method
        case '1502': // Given payment method couldn’t be verified
        case '2202': // Possible fraud detected
        case '2204': // Insufficient funds
        case '2205': // The card used for the transaction is blocked
        case '2207': // The transaction was refused
        case '2210': // The shopper’s bank does not support or does not allow this type of transaction
        case '5001': // Payment method is not found
        case '5002': // Payment method is not verified
        case '5003': // Payment method data supplied is not valid. Payment method is not verified
            return 'Betalingen ble ikke godkjent. Vennligst prøv igjen.'
        default:
            return 'En feil oppsto hos betalingsformidleren. Kontakt vår kundeservice dersom dette inntreffer flere ganger.'
    }
}

export function generalError(errorCode, error) {
    const errorType = errorCode.replace(/^error_/, '')
    switch (errorType) {
        case 'ADYEN_INVALID_AMOUNT':
            return 'Betaling kunne ikke gjennomføres'
        case 'AUTHENTICATION_FAILED_3DSECURE':
            return 'Betaling kunne ikke gjennomføres'
        case 'CARD_BLOCKED':
            return 'Betaling kunne ikke gjennomføres'
        case 'CARD_EXPIRED':
            return 'Betaling kunne ikke gjennomføres'
        case 'CARD_OUTSIDE_VALID_TIME_PERIOD':
            return 'Ugyldig kortinformasjon'
        case 'CARD_VERIFICATION_FAILED':
            return 'Ugyldig kortinformasjon'
        case 'CREDIT_CARD_NOT_FOUND':
            return 'Finner ikke kortinformasjon'
        case 'EMPTY_GIFT_MESSAGE':
            return 'Feil: Gavemeldingen kan ikke være tom'
        case 'EXPORT_FAILED':
            return 'En vare i handlekurven din er dessverre utsolgt'
        case 'FRAUD_DETECTED':
            return 'Betaling kunne ikke gjennomføres'
        case 'FREIGHT_NOT_FOUND':
            return 'Kunne ikke beregne frakt'
        case 'INSUFFICIENT_FUNDS':
            return 'Betaling kunne ikke gjennomføres'
        case 'INVALID_CARD_EXPIRY_DATE':
            return 'Ugyldig kortinformasjon'
        case 'INVALID_OR_MISSING_CARD_CVC':
            return 'Ugyldig kortinformasjon'
        case 'INVALID_OR_MISSING_CARD_CVC_SPECIFIED':
            return 'Ugyldig eller manglende CVC-kode'
        case 'INVALID_OR_MISSING_CARD_HOLDER_NAME':
            return 'Ugyldig kortinformasjon'
        case 'INVALID_OR_MISSING_CARD_NUMBER_SPECIFIED':
            return 'Ugyldig kortinformasjon'
        case 'PAYMENT_FAILED':
            return 'Betalingen ble ikke godkjent. Vennligst prøv igjen.'
        case 'PAYMENT_METHOD_DATA_SUPPLIED_IS_NOT_VALID':
            return 'Ugyldig kortinformasjon'
        case 'PAYMENT_METHOD_IS_NOT_FOUND':
            return 'Betaling kunne ikke gjennomføres'
        case 'PAYMENT_METHOD_IS_NOT_VERIFIED':
            return 'Betaling kunne ikke gjennomføres'
        case 'PPA_CONNECTION_ERROR':
            return 'Betaling kunne ikke gjennomføres'
        case 'PPA_ERROR_OCCURRED':
            return 'Betaling kunne ikke gjennomføres'
        case 'PPA_VALIDATION_ERROR_OCCURRED':
            return 'Betaling kunne ikke gjennomføres'
        case 'SPT_INTERNAL_ERROR':
            return 'Betaling kunne ikke gjennomføres'
        case 'TRANSACTION_NOT_SUPPORTED':
            return 'Betaling kunne ikke gjennomføres'
        case 'TRANSACTION_REFUSED':
            return 'Betaling kunne ikke gjennomføres'
        case 'VIPPS_TRANSACTION_CANCELLED_BY_USER':
            return 'Betaling avbrutt'
        case 'chargeError_cardExpired':
            return 'Betalingskortet er utløpt. Vennligst prøv igjen med et annet betalingskort.'
        case 'chargeError_errorMessage':
            return 'Feilmelding: {{ errorMessage }}'
        case 'chargeError_invalidCVC':
            return 'Sikkerhetskoden (CVC) til angitt kort ser ikke ut til å være gyldig.'
        case 'chargeError_invalidCardHolderName':
            return 'Kortinnehaverens navn til angitt kort ser ikke ut til å være gyldig.'
        case 'chargeError_invalidCardNumber':
            return 'Kortnummeret til angitt kort ser ikke ut til å være gyldig.'
        case 'chargeError_invalidExpirationDate':
            return 'Utløpsdatoen til angitt kort ser ikke ut til å være gyldig.'
        case 'chargeError_paymentRejected':
            return 'Betalingen ble ikke godkjent. Vennligst prøv igjen.'
        case 'chargeError_termsNotAccepted':
            return 'Du ser ikke ut til å ha akseptert de nye brukervilkårene for Morgenlevering. Vennligst gå til forsiden, og logg ut og inn før du forsøker igjen.'
        case 'chargeError_timeout':
            return 'På grunn av et tidsavbrudd kan ikke angitte kortdetaljer brukes lenger. Vennligst last siden på nytt og prøv igjen.'
        case 'chargeError_unknown':
            return 'En feil oppsto hos betalingsformidleren. Kontakt vår kundeservice dersom dette inntreffer flere ganger.'
        case 'couponError_couponAuthenticatedOnly':
            return 'Du må logge inn for å bruke rabattkoden.'
        case 'couponError_couponError_couponExpired':
            return 'Rabattkoden er utløpt'
        case 'couponError_couponExpired':
            return 'Rabattkoden er ikke lenger gyldig.'
        case 'couponError_couponInvalidCode':
            return 'E-postadressen må legges inn før rabattkoden kan godkjennes\n\n'
        case 'couponError_couponLimitExceeded':
            return 'Rabattkoden er utbrukt.'
        case 'couponError_couponMissingCoverage':
            return 'Rabattkoden dekker ikke noen av produktene du har valgt.'
        case 'couponError_couponNotActiveYet':
            return 'Rabattkoden er ikke gyldig ennå.'
        case 'couponError_couponUserLimitExceeded':
            return 'Rabattkoden kan ikke brukes av enda flere brukere.'
        case 'couponError_invalidCoupon':
            return 'E-postadressen må legges inn før rabattkoden kan godkjennes\n\n'
        case 'couponError_invalidData':
            return 'Rabattkoden kan ikke valideres.'
        case 'couponError_missingUserData':
            return 'Du må logge inn eller fylle inn e-postadresse for å bruke rabattkode.'
        case 'couponError_undefined':
            return 'Rabattkoden kan ikke valideres (ukjent feil).'
        case 'couponInvalidCode':
            return 'Rabattkoden er dessverre ikke gyldig lengre'
        case 'couponLimitExceeded':
            return 'Rabattkoden er brukt for mange ganger'
        case 'distribution_backendError':
            return 'Vi klarte ikke snakke med budene for å få levert pakken din. Dette er vår feil, ikke din. Ta gjerne kontakt med kundeservice eller prøv igjen senere.'
        case 'distribution_capacityLimitReached':
            return 'Ånei! Budet har dessverre ikke kapasitet til flere pakker til ditt område. Prøv med en annen leveringsdag!'
        case 'distribution_noDistribution':
            return 'Ajaj, vi leverer dessverre ikke til din adresse på denne dagen. Prøv igjen med en annen adresse!'
        case 'duplicateOrder':
            return 'Orderen eksisterer allerede, legg til en ny vare i handlekurven, fjern den og prøv på nytt'
        case 'editAddress_ADDRESS_NOT_EXISTS':
            return 'Adressen du prøver å endre finnes ikke i vårt system. Fjern heller adressen og opprett en ny.'
        case 'editAddress_ADDRESS_NO_CARRIER_SUPPORT':
            return 'Vi kan dessverre ikke levere til denne adressen 😞'
        case 'editAddress_INTERNAL_SERVER_ERROR':
            return 'Ai, noe skurrer i maskineriet. Prøv en gang til.'
        case 'editAddress_UPSTREAM_FAILURE':
            return 'Ai, noe skurrer i maskineriet. Prøv en gang til.'
        case 'emptyCart':
            return 'Handlekurven er tom'
        case 'emptyProduct':
            return 'Du må ha minst én av {{ productTitle }}'
        case 'exportFailed': {
            if (error?.data && error.data['FAILED_ORDER_LINE_TITLES']) {
                const failedOrderLines = error.data['FAILED_ORDER_LINE_TITLES']
                return (
                    'Følgende varer i handlekurven din er utsolgt på valgt leveringsdato: ' +
                    failedOrderLines +
                    '.'
                )
            }

            return 'En av varene i handlekurven din er utsolgt. Gå gjennom handlekurven for å se hvilket og fjern dette produktet.'
        }
        case 'internalError':
            return 'Intern systemfeil'
        case 'invalidAddress':
            return 'Ugyldig adresse'
        case 'invalidCustomer':
            return 'Ugyldig bruker'
        case 'invalidCustomerEmail':
            return 'Ugyldig e-post'
        case 'invalidDate':
            return 'Det er dessverre ikke mulig å levere til valgt dato'
        case 'invalidDeliveryDate':
            return 'Ugyldig leveringsdato'
        case 'invalidDistrDate':
            return 'Bestillingsfristen til leveringsdagen du har valgt er passert. Velg en annen leveringsdag.'
        case 'invalidProductDeadline':
            return 'Følgende vare kan dessverre ikke leveres på leveringsdatoen du har valgt: {{ invalidProductNames }}.\n\nFor å gjøre en bestilling må du fjerne denne fra handlekurven.'
        case 'invalidProductId':
            return 'Følgende vare kan dessverre ikke leveres til adressen du har valgt: {{ invalidProductNames }}.\n\nFor å gjøre en bestilling må du fjerne denne fra handlekurven.'
        case 'invalidProductId_plural':
            return 'Følgende varer kan dessverre ikke leveres til adressen du har valgt: {{ invalidProductNames }}.\n\nFor å gjøre en bestilling må du fjerne disse fra handlekurven.'
        case 'invalidProductRegionRelation':
            return 'Varen kan dessverre ikke leveres til valgt adresse. Fjern varen og legg den til på nytt\n'
        case 'invalidType':
            return 'Ugyldig type'
        case 'missingAdyenCard':
            return 'Feil i kortinformasjon'
        case 'missingCustomerEmail':
            return 'E-post mangler'
        case 'missingDeadlineInformation':
            return 'Mangler informasjon om deadline'
        case 'missingUnauthenticatedUserData':
            return 'Mangler kontaktinformasjon'
        case 'network':
            return 'Ops! Her har det kommet noen smuler i maskineriet. Kom tilbake til bakeverden ved å klikke på knappen under.'
        case 'noCardSet':
            return 'Mangler kortinformasjon'
        case 'noCarrierSupport':
            return 'Beklager, vi leverer ikke til din adresse på valgt dato.'
        case 'orderValidationErrorTooManyRecipients':
            return 'Ordren inneholder flere enn maksgrensen på 10 mottakere. '
        case 'orderValidationException':
            return 'Ugyldig ordre'
        case 'order_canNotBeCancelled':
            return 'Orderen kan ikke lenger kanselleres'
        case 'order_lockedOrder':
            return 'Det er dessverre for sent å endre denne ordren.'
        case 'order_outOfStock':
            return 'Vi er dessverre tomme for varen den valgte datoen.'
        case 'outOfStock':
            return 'Vi er dessverre tomme for ett eller flere produkter i din handlekurv'
        case 'placeSubscription':
            return 'Kunne ikke lagre fast levering'
        case 'priceMatch':
            return 'Oops! Det kan se ut til at prisen du ser i nettbutikken ikke er oppdatert. Vennligst oppfrisk siden slik at vi kan gi deg riktig pris :)'
        case 'priceMissing':
            return 'Kunne ikke finne pris'
        case 'productNotFound':
            return 'Kunne ikke finne angitt produkt'
        case 'refundFailed':
            return 'Kunne ikke refundere beløp'
        case 'refundRequestError':
            return 'Teknisk feil. Beløpet har kun blitt reservert. Ta kontakt med vårt kundesenter ved spørsmål. '
        case 'saveAddress_cannotDeliver':
            return 'Vi kan dessverre ikke levere til denne adressen 😔'
        case 'saveAddress_internal':
            return 'Lagring av adresse feilet. Vennligst prøv igjen senere.'
        case 'saveAddress_primary':
            return 'Lagring av hovedadresse feilet. Vennligst prøv igjen senere.'
        case 'singleAddressCheckFailed':
            return 'Feil med adressesjekk'
        case 'systemChargeError':
            return 'Systemet kunne ikke trekke ditt kort'
        case 'undefined':
            return 'Beklager, det skjedde en feil 😟'
        case 'unknownError':
            return 'En ukjent feil har oppstått'
        case 'unknownOrderType':
            return 'Ukjent ordre'
        case 'usedSessionId':
            return 'En aktiv ordre eksisterer allerede'
        case 'userChargeError':
            return 'Kunne ikke trekke beløp fra ditt kort'
        case 'validationError':
            return 'Kunne ikke validere'
        case 'vatRateMatch':
            return 'Kunne ikke finne moms'
        case 'webServiceError':
            return 'Vi kan dessverre ikke levere til valgt adresse 😕'
    }
}
