import { cartReducer as cart } from 'cart'
import { newCheckoutReducer as newCheckout } from 'checkout'
import { connectRouter } from 'connected-react-router'
import { dynamicHomePageReducer as dynamicHomePage } from 'home'
import { notificationReducer as notification } from 'notification'
import { combineReducers } from 'redux'
import addressHelper from './addressHelperReducer'
import address from './addressReducer'
import checkout from './checkoutReducer'
import b2bCompany from './b2bCompanyReducer'
import coupon from './couponReducer'
import distribution from './distributionReducer'
import features from './featuresReducer'
import newPaymentMethod from './newPaymentMethodReducer'
import order from './orderReducer'
import paymentMethod from './paymentMethodReducer'
import personalInfo from './personalInfoReducer'
import producers from './producersReducer'
import products from './productsReducer'
import push from './pushReducer'
import region from './regionReducer'
import subscriptionNotification from './subscriptionNotificationReducer'
import subscription from './subscriptionReducer'
import ui from './uiReducer'
import user from './userReducer'

export default (history) =>
    combineReducers({
        address,
        addressHelper,
        cart,
        checkout,
        b2bCompany,
        coupon,
        distribution,
        dynamicHomePage,
        features,
        newPaymentMethod,
        newCheckout,
        notification,
        order,
        paymentMethod,
        personalInfo,
        producers,
        products,
        push,
        region,
        router: connectRouter(history),
        subscription,
        subscriptionNotification,
        ui,
        user,
    })
