var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk } from '@reduxjs/toolkit';
import { generateCVCToken } from 'actions/NewPaymentMethod';
import { submit3DSecureChallengeForm } from 'actions/SecureChallenge';
import { placeSubscriptionOrder as placeSubscriptionOrderLegacy } from 'actions/Checkout';
import axios from 'axios';
import { goBack, push } from 'connected-react-router';
import { addOrderError, concatGiftMessageData, corePurchaseComplete, setShowRelatedProductsInterrupt, } from '../store/checkoutSlice';
import { addressIsEqual, makeDeliveryAddress } from 'utilities/address';
import { getOrderContactInfo, getSelectedPaymentOption, getSingleOrderAddress } from '../order';
const mapAddressToRecipient = (address, regionId, deliverySms) => {
    return {
        firstName: address.firstName,
        lastName: address.lastName,
        mobile: address.mobile,
        zipCode: address.zip,
        countryCode: address.countryCode,
        streetName: address.streetName,
        flatNumber: address.flatNo,
        streetNumber: address.streetNo,
        postalName: address.city,
        floorType: address.floorType,
        deliveryPointId: address.deliveryPointId,
        floorNumber: address.floorNo,
        entrance: address.entrance,
        co: address.co,
        regionId,
        deliverySms,
    };
};
const getBillingInformation = (validSelectedPaymentOption, state, dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    switch (validSelectedPaymentOption.type) {
        case 'NEW_CREDIT_CARD':
            return {
                paymentMethodType: validSelectedPaymentOption.paymentMethodType,
                cardToken: state.newPaymentMethod.token,
                saveCardConsent: state.newPaymentMethod.saveCardConsent,
            };
        case 'CREDIT_CARD':
            return {
                paymentMethodType: validSelectedPaymentOption.paymentMethodType,
                cardToken: yield dispatch(generateCVCToken()),
                paymentMethodId: validSelectedPaymentOption.paymentMethodId,
            };
        case 'VIPPS':
            return {
                paymentMethodType: validSelectedPaymentOption.paymentMethodType,
            };
        case 'B2B_INVOICE':
            return {
                paymentMethodType: validSelectedPaymentOption.paymentMethodType
            };
        case 'NO_PAYMENT':
            return {};
    }
});
export const placeOrder = createAsyncThunk('newCheckout/placeOrder', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const state = thunkApi.getState();
    const dispatch = thunkApi.dispatch;
    const cartProducts = state.cart.products;
    const products = cartProducts.map((product) => ({
        id: product.id,
        quantity: product.quantity,
    }));
    const recipients = state.newCheckout.orderType === 'group'
        ? state.newCheckout.recipients
        : [getSingleOrderAddress(state.address, state.addressHelper)];
    const totalPrice = state.cart.total *
        (state.newCheckout.orderType === 'group' ? state.newCheckout.recipients.length : 1);
    const deliverySms = state.newCheckout.orderType !== 'single' ? true : state.personalInfo.deliverySms;
    const isGift = state.newCheckout.orderType === 'single' ? false : true;
    const deliveries = recipients.map((recipient) => ({
        products,
        recipient: mapAddressToRecipient(recipient.deliveryAddress, state.region.id, deliverySms),
        isGift,
        giftMessage: concatGiftMessageData(state.newCheckout.giftMessage),
    }));
    const selectedPaymentOption = getSelectedPaymentOption(state.checkout, state.newPaymentMethod, state.paymentMethod, state.cart);
    if (selectedPaymentOption.type === 'NONE') {
        return;
    }
    const billingInformation = yield getBillingInformation(selectedPaymentOption, state, dispatch);
    const customer = Object.assign(Object.assign({}, getOrderContactInfo(state.address, state.personalInfo, state.newCheckout, state.addressHelper, state.b2bCompany)), { billingInformation, deliverySms: state.personalInfo.deliverySms });
    const checkout = {
        sessionId: state.cart.id,
        customer,
        deliveries,
        totalPrice: totalPrice.toFixed(2),
        deliveryDate: state.checkout.deliveryDate,
        couponCode: state.newCheckout.orderType !== 'group' ? (_a = state.coupon.discount) === null || _a === void 0 ? void 0 : _a.code : undefined,
        promotions: (_b = state.coupon.discount.promotions) !== null && _b !== void 0 ? _b : [],
        redirectToBeta: process.env.IS_BETA === 'true',
    };
    const url = `${process.env.CORE_API}/order/v2`;
    dispatch({ type: 'PLACE_ORDER' });
    dispatch(push('/checkout/processing'));
    return axios.post(url, checkout).then((res) => {
        // TODO: {status: "ok"} er for bakoverkompatabilitet med gammel kode. Fjern etterhvert
        if (res.status === 204) {
            dispatch(corePurchaseComplete(state.cart.id));
            dispatch({
                type: 'PLACE_ORDER_SUCCESS',
                payload: {
                    data: { status: 'ok' },
                },
            });
            dispatch(setShowRelatedProductsInterrupt(true));
            dispatch(push('/checkout/complete'));
        }
        else if (res.status === 202 && res.data.status === 'payment-challenge') {
            submit3DSecureChallengeForm(res);
        }
        else if (res.status === 202 && res.data.status === 'vipps-redirect') {
            window.location.href = res.data.redirectUrl;
        }
        return res;
    }, (error) => {
        const res = error.response;
        dispatch(addOrderError(res.data));
        dispatch(goBack());
        throw res;
    });
}));
export const placeSubscriptionOrder = createAsyncThunk('newCheckout/placeSubscriptionOrder', (_, thunkApi) => __awaiter(void 0, void 0, void 0, function* () {
    var _c;
    const state = thunkApi.getState();
    const dispatch = thunkApi.dispatch;
    const email = state.user.isLoggedIn ? state.user.jwtData.spidEmail : null;
    const subscription = state.subscription;
    const personalInfo = state.personalInfo;
    const subscriptionAddress = state.address.subscription;
    const optinForNews = state.checkout.optinForNews;
    const preferredPaymentMethod = state.paymentMethod.preferred;
    // If a new address is used it might not have transportProduct.
    const transportProduct = (_c = state.address.addresses.find((x) => addressIsEqual(x.deliveryAddress, subscriptionAddress, false))) === null || _c === void 0 ? void 0 : _c.deliveryAddress.transportProduct;
    const deliveryAddress = makeDeliveryAddress(subscriptionAddress, personalInfo, subscriptionAddress.transportProduct || transportProduct);
    dispatch(placeSubscriptionOrderLegacy(subscription, deliveryAddress, email, preferredPaymentMethod, optinForNews));
}));
