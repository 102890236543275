import { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PaymentMethodType } from 'types/paymentMethodType';
import { isValidAddress, isValidContactInfo, isValidCvc } from 'utilities/validators';
import { CheckoutValidationContext } from './checkoutValidationContext';
import { getOrderContactInfo, getSelectedPaymentOption, getSingleOrderAddress } from './order';
import { concatGiftMessageData, corePurchaseComplete, } from './store/checkoutSlice';
export function useSelectedPaymentOption() {
    const checkoutState = useSelector((state) => state.checkout);
    const newPaymentMethodState = useSelector((state) => state.newPaymentMethod);
    const paymentMethodState = useSelector((state) => state.paymentMethod);
    const cartState = useSelector((state) => state.cart);
    return useMemo(() => getSelectedPaymentOption(checkoutState, newPaymentMethodState, paymentMethodState, cartState), [checkoutState, newPaymentMethodState, paymentMethodState, cartState]);
}
export function useOrderContactInfo() {
    const addressState = useSelector((state) => state.address);
    const personalInfoState = useSelector((state) => state.personalInfo);
    const newCheckoutState = useSelector((state) => state.newCheckout);
    const b2bCompanyState = useSelector((state) => state.b2bCompany);
    const addressHelperState = useSelector((state) => state.addressHelper);
    return useMemo(() => getOrderContactInfo(addressState, personalInfoState, newCheckoutState, addressHelperState, b2bCompanyState), [addressState, personalInfoState, newCheckoutState, b2bCompanyState]);
}
export function useSingleOrderAddress() {
    const addressState = useSelector((state) => state.address);
    const addressHelperState = useSelector((state) => state.addressHelper);
    return getSingleOrderAddress(addressState, addressHelperState);
}
export function useCheckoutValidity() {
    var _a;
    const paymentMethodType = useSelector((state) => state.checkout.paymentMethodType);
    const b2bCustomer = useSelector((state) => state.personalInfo.b2bCustomer);
    const { orderType, recipients } = useSelector((state) => state.newCheckout);
    const pricePerRecipient = useSelector((state) => state.cart.total);
    const { cvc } = useSelector((state) => state.newPaymentMethod);
    const giftMessage = useSelector((state) => concatGiftMessageData(state.newCheckout.giftMessage));
    const cartProducts = useSelector((state) => state.cart.products);
    const selectedPaymentOption = useSelectedPaymentOption();
    const orderContactInfo = useOrderContactInfo();
    const singleOrderDeliveryAddress = (_a = useSingleOrderAddress()) === null || _a === void 0 ? void 0 : _a.deliveryAddress;
    const paymentMethodTypeIsVipps = paymentMethodType === PaymentMethodType.VIPPS;
    const paymentMethodTypeIsB2BInvoice = paymentMethodType === PaymentMethodType.B2B_INVOICE;
    const numberOfRecipients = Math.max(orderType === 'group' ? recipients.length : 1, 1);
    const totalPrice = pricePerRecipient * numberOfRecipients;
    const showMeansOfPayment = totalPrice > 0;
    const hasValidPayment = selectedPaymentOption.type !== 'NONE';
    const hasGiftMessageIfRequired = orderType === 'single' || !!giftMessage;
    const hasValidNumberOfRecipientsIfGroup = 
    // If order type is different than 'group' we will have 1 recipient
    // If order type is 'group' we only allow from 2 to 10 recipients.
    // For b2bCustomers the order type is always group and we don't pose any limits on the number of recipients
    orderType !== 'group' || b2bCustomer || (!b2bCustomer && recipients.length >= 2 && recipients.length <= 10);
    const hasActiveAddressIfSingleOrder = orderType === 'group' || isValidAddress(singleOrderDeliveryAddress);
    const hasValidCvC = isValidCvc(cvc) || paymentMethodTypeIsVipps || paymentMethodTypeIsB2BInvoice || !showMeansOfPayment;
    const hasNonEmptyCart = cartProducts.length > 0;
    const hasValidCartProductsForRegion = !cartProducts.some((cartProduct) => cartProduct.disabledForRegion);
    const validityRequirements = [
        {
            valid: hasValidPayment,
            invalidReason: 'noValidPayment',
        },
        {
            valid: hasNonEmptyCart,
            invalidReason: 'noItemsInCart',
        },
        {
            valid: hasValidCartProductsForRegion,
            invalidReason: 'invalidProductsForRegion',
        },
        {
            valid: hasGiftMessageIfRequired,
            invalidReason: 'noGiftMessage',
        },
        {
            valid: hasValidNumberOfRecipientsIfGroup,
            invalidReason: 'invalidNumberOfRecipients',
        },
        {
            valid: hasActiveAddressIfSingleOrder,
            invalidReason: 'noActiveAddress',
        },
        {
            valid: hasValidCvC,
            invalidReason: 'invalidCvc',
        },
        {
            valid: isValidContactInfo(orderContactInfo),
            invalidReason: 'invalidContactInfo',
        },
    ];
    const invalidReasons = validityRequirements
        .filter((validityRequirement) => !validityRequirement.valid)
        .map((validityRequirement) => validityRequirement.invalidReason);
    return {
        isValid: invalidReasons.length === 0,
        invalidReasons,
    };
}
export function useOnInvalidOrderAttempt(claimResponsibility, onResponsibilityClaim) {
    const orderAttempts = useSelector((state) => state.newCheckout.orderAttempts);
    const responsiblityUnclaimedRef = useContext(CheckoutValidationContext);
    useEffect(() => {
        if (orderAttempts > 0 && claimResponsibility && responsiblityUnclaimedRef.current) {
            responsiblityUnclaimedRef.current = false;
            onResponsibilityClaim();
        }
    }, [orderAttempts, claimResponsibility]);
}
export function useCheckoutCompletedSpinnerLogic() {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.newCheckout.orderConfirmationDataLoading);
    const hasErrored = useSelector((state) => state.newCheckout.orderConfirmationDataErrored);
    const orderConfirmationData = useSelector((state) => state.newCheckout.orderConfirmationData);
    const orderSessionId = useSelector((state) => state.cart.id);
    // Return values
    const [redirectToHome, setRedirectToHome] = useState(false);
    const [showSpinner, setShowSpinner] = useState(true);
    // State for error handling
    const [retries, setRetries] = useState(0);
    const [currentlyRetrying, setCurrentlyRetrying] = useState(false);
    const MAX_NUMBER_OF_RETRIES = 3;
    const MILLISECONDS_BETWEEN_RETRIES = 200;
    useEffect(() => {
        if (!isLoading && !hasErrored && orderConfirmationData == null) {
            // In this case the user navigated directly to /checkout/complete
            // without entering an order. We solve this by simply redirecting
            // to "/"
            setRedirectToHome(true);
        }
        if (!isLoading && !hasErrored && orderConfirmationData != null) {
            setShowSpinner(false);
        }
        if (hasErrored) {
            setRetries(retries + 1);
            if (retries > MAX_NUMBER_OF_RETRIES) {
                setRedirectToHome(true);
            }
            if (!currentlyRetrying) {
                setCurrentlyRetrying(true);
                setTimeout(() => {
                    dispatch(corePurchaseComplete(orderSessionId));
                    setCurrentlyRetrying(false);
                }, MILLISECONDS_BETWEEN_RETRIES);
            }
        }
    }, [orderConfirmationData, isLoading, hasErrored]);
    return { redirectToHome, showSpinner };
}
